var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"bg-body mt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 py-4"},[_c('h2',{staticClass:"mt-3 text-white"},[_vm._v("No importa donde estés,Traxporta lo hace más fácil.")]),_c('p',{staticClass:"text-white mt-3"},[_vm._v(" Traxporta web, más opciones, en menos tiempo. Fácil ingreso desde computadoras o tabletas.Traxporta móvil, sin importar donde te encuentres, cargas y transportadores al instante. ")]),_c('a',{staticClass:"btn",staticStyle:{"background-color":"#b8db15"},attrs:{"href":_vm.URL_REGISTER,"target":"_blank","type":"button"}},[_vm._v("Únete")])]),_vm._m(3)])])]),_vm._m(4),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 py-4"},[_c('h6',[_vm._v("Pagos inmediatos")]),_c('p',{staticClass:"text-muted"},[_vm._v("¿30 a 90 días para recibir el pago? Es tiempo pasado. Con Traxporta pagos en menos de 7 dias en nuestras cargas Premium.")]),_c('h6',[_vm._v("Fácil reserva")]),_c('p',{staticClass:"text-muted"},[_vm._v("Reserva al instante. Recibe confirmación sin preocuparte.")]),_c('h6',[_vm._v("Precio real")]),_c('p',{staticClass:"text-muted"},[_vm._v("Mira el precio de la carga. Precio escogido. Dinero recibido. Sin sorpresas")]),_c('h6',[_vm._v("Ruta Completa")]),_c('p',{staticClass:"text-muted"},[_vm._v("Regresas con tu camión desocupado? Pierdes dinero por viajes vacíos? Con Traxporta es tiempo pasado. Planea tu ruta, tu regreso y haz tu viaje rentable.")]),_c('a',{staticClass:"btn",staticStyle:{"background-color":"#b8db15"},attrs:{"href":_vm.URL_REGISTER,"target":"_blank","type":"button"}},[_vm._v("Únete")])]),_vm._m(5)])])]),_vm._m(6),_vm._m(7),_vm._m(8),_c('blog')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-muted"},[_vm._v("Sin turnos para asignación de cargas, sin recargos adicionales, en un proceso rápido, transparente y tecnológico. El pago puede ser recibido incluso en menos de 7 días después de recibir evidencias físicas.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-5",staticStyle:{"margin-bottom":"70px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"img-store",attrs:{"src":require("@/assets/images/Cero-intermediarios.png")}}),_c('h6',{staticClass:"mt-3"},[_vm._v("Pagos inmediatos en nuestras cargas Premium")]),_c('p',{staticClass:"text-muted mt-3"},[_vm._v("Pagos en menos de 7 días después de recibir evidencias físicas, cuando lo necesites y para lo que necesites.")])]),_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"img-store",attrs:{"src":require("@/assets/images/Miles-de-cargas.png")}}),_c('h6',{staticClass:"mt-3"},[_vm._v("Cero intermediarios")]),_c('p',{staticClass:"text-muted mt-3"},[_vm._v("Traxporta hace todo por ti, documentación enviada, pago recibido, sin largos procesos, sin más intermediarios.")])]),_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"img-store",attrs:{"src":require("@/assets/images/Informacion.png")}}),_c('h6',{staticClass:"mt-3"},[_vm._v("Amplia oferta")]),_c('p',{staticClass:"text-muted mt-3"},[_vm._v("Ya sea una o varias cargas a enviar, miles de transportadores dispuestos a ayudar.")])]),_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"img-store",attrs:{"src":require("@/assets/images/666116f5-4690-4362-9a5c-f0cc01a30863.png"),"width":"15%"}}),_c('h6',{staticClass:"mt-3"},[_vm._v("Información al instante")]),_c('p',{staticClass:"text-muted mt-3"},[_vm._v("¿Nuevas cargas para transportar? ¿Cargas para asignar? Notificaciones al instante.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Cargas a un clic de distancia. Busca como quieras, cuando quieras. Miles de cargas esperan por ti.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-center"},[_c('img',{staticClass:"body-loads",attrs:{"src":require("@/assets/images/traxporta-768x571.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Características adicionales")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-center"},[_c('img',{staticClass:"body-loads",attrs:{"src":require("@/assets/images/screen-dashboard-webapp.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Lo que dicen de nosotros")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#f7f7f7"}},[_c('div',{staticClass:"container p-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 p-3"},[_c('div',{staticClass:"card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center my-3"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("@/assets/images/40f94035-052d-4375-b9b6-8eb7fcf19ef6.png")}})]),_c('p',{staticClass:"card-text"},[_vm._v(" “El envío de documentación digital me ahorra mucho tiempo, solo les tomo fotos y envío. No importa donde me encuentre\" ")])])])]),_c('div',{staticClass:"col-md-4 p-3"},[_c('div',{staticClass:"card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center my-3"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("@/assets/images/c0a3f1d2-196a-43e9-8d96-909ad2e095f6.png")}})]),_c('p',{staticClass:"card-text"},[_vm._v(" \"Ya no tengo que esperar 30 días para recibir el pago, si necesito dinero para combustible puedo tenerlo fácilmente, no hay muchas preguntas.\" ")])])])]),_c('div',{staticClass:"col-md-4 p-3"},[_c('div',{staticClass:"card",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center my-3"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("@/assets/images/515aef60-d7f6-4143-8b0b-d1dd5c845873.png")}})]),_c('p',{staticClass:"card-text"},[_vm._v(" \"La solución Web es excelente, cuando necesito administrar muchas cargas con mi equipo de trabajo me ahorra mucho tiempo.\" ")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#f7f7f7"},attrs:{"id":"aliados"}},[_c('div',{staticClass:"container py-5"},[_c('div',{staticClass:"text-center py-5"},[_c('img',{staticStyle:{"max-width":"300px","max-height":"150px"},attrs:{"src":require("@/assets/images/traxion-1.png")}})]),_c('div'),_c('div',{staticClass:"row align-items-center text-center"},[_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/mym-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/egoba-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/afn-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/bisonte-2.png")}})])]),_c('div',{staticClass:"row align-items-center text-center"},[_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/grupo-sid-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/redpack-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/lipu-2.png")}})]),_c('div',{staticClass:"col-md-3 col-sm-6 my-4"},[_c('img',{staticClass:"icon-partner",attrs:{"src":require("@/assets/images/TRAXI_LOGOTIPO_01.png")}})])]),_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"max-width":"250px","max-height":"150px"},attrs:{"src":require("@/assets/images/traxion-2.png")}})])])])
}]

export { render, staticRenderFns }