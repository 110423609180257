<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <h2>Gana tiempo con tus cargas</h2>
          <p>Despídete de esa larga espera para la asignación de una carga. Sin recargos adicionales. Elige las cargas que quieras, cuando quieras.</p>
        </div>
      </div>
    </div>

    <div class="container mt-5" style="margin-bottom: 70px;">
      <div class="row">
        <div class="col-md-4">
          <img class="img-store" src="@/assets/images/Cero-intermediarios.png" />
          <h6 class="mt-3">Cero intermediarios</h6>
          <p class="text-muted mt-3">Traxporta hace todo por ti. Procesos rápidos, transparentes y seguros en la misma plataforma.</p>
        </div>
        <div class="col-md-4">
          <img class="img-store" src="@/assets/images/Miles-de-cargas.png" />
          <h6 class="mt-3">Miles de cargas</h6>
          <p class="text-muted mt-3">Accede a más clientes de forma inmediata. Miles de cargas esperan por ti. Recibe cargas de acuerdo a tus necesidades.</p>
        </div>
        <div class="col-md-4">
          <img class="img-store" src="@/assets/images/Informacion.png" />
          <h6 class="mt-3">Información al instante</h6>
          <p class="text-muted mt-3">¿Nuevas cargas para transportar? ¿Cargas para asignar? Notificaciones al instante.</p>
        </div>
      </div>
    </div>
    <div class="bg-body mt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <img class="body-loads" style="height:400px;margin-top: -50px;" src="@/assets/images/telefonos.png" />
          </div>
          <div class="col-md-6 py-4">
            <h2 class="mt-3 text-white">Miles de cargas esperan por ti</h2>
            <p class="text-white mt-3">
              Encuentra la carga cerca de ti a solo un clic. Activa las alertas en tu teléfono y recibe cargas de acuerdo a tus necesidades.
            </p>
            <a :href=URL_REGISTER target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6 py-4">
          <h2>Control total en tu mano</h2>
          <p class="text-muted">Organiza tu ruta con total flexibilidad y asistencia durante todo tu recorrido. Comunícate con tus operadores y clientes sin salir de la aplicación.</p>
          <div>
            <a :href=URL_REGISTER target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-store" src="@/assets/images/Bloque-02.png" style="width: 100%;"/>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/image-2.png" width="100%" />
        </div>
        <div class="col-md-6 py-4">
          <h2>Pagos más rápidos y seguros</h2>
          <p class="text-muted">Pagos en menos días, cuando lo necesites y para lo que lo necesites. Proporciona tu documentación de la entrega y obtén tu pago en menos de siete días.</p>
          <div>
            <a :href=URL_REGISTER target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-md-6 py-4">
          <h2>Reconocimiento por tu servicio</h2>
          <p class="text-muted">Haz que tu empresa crezca con reseñas productivas y contribuye a la comunidad más grande de
            transportistas dejando tu opinión después de entregar una carga.</p>
          <div>
            <a :href=URL_REGISTER target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/Bloque-04.png" width="100%" />
        </div>
      </div>
    </div>

    <div class="container my-5">
      <h2 class="text-center">Miles de transportistas ya forman parte de la red más grande de México</h2>
    </div>

    <div style="background: #f7f7f7">
      <div class="container p-5">
        <div class="row">
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/40f94035-052d-4375-b9b6-8eb7fcf19ef6.png" style="width: 100px"/>
                </div>
                <p class="card-text">
                  "Necesitaba seguridad en mis pagos y con Traxporta lo obtuve. Puedo ver el precio de la carga y eso es lo que recibo, sin sorpresas, sin cargos extra. Solo envío mi documentación de entrega y listo"
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/c0a3f1d2-196a-43e9-8d96-909ad2e095f6.png" style="width: 100px"/>
                </div>
                <p class=" card-text">
                  “Traxporta me ayudó a tener más y mejores clientes. Yo elijo cuántos viajes hago, a dónde y cuánto ganaré. Toda la información está en la app”
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-3">
            <div class="card" style="width: 100%;">
              <div class="card-body">
                <div class="text-center my-3">
                  <img src="@/assets/images/515aef60-d7f6-4143-8b0b-d1dd5c845873.png" style="width: 100px"/>
                </div>
                <p class="card-text">
                  “Con Traxporta pude organizar a todos mis camiones de entrega. Puedo ver los viajes en tiempo real, verificar las rutas, comunicarme con mis operadores y con los clientes sin salir de la plataforma”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="container mt-5">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-store w-100" src="@/assets/images/Bloque-05.png" width="100%" />
        </div>
        <div class="col-md-6">
          <h3>Más que una app Traxporta es tu aliado.</h3>
          <p class="text-muted">un beneficio para ti es un incentivo para nosotros</p>
          <div>
            <table>
              <tr>
                <td>
                    <img class="img-store w-100" src="@/assets/images/icon-01.png" width="100%" />
                </td>
                <td class="px-md-3">
                  <h6>Descuentos en gasolina</h6>
                  <p class="text-muted">
                    Maximiza tus ganancias reduciendo tus gastos en combustible con nuestros descuentos en gasolina, estés donde estés. 
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img class="img-store w-100" src="@/assets/images/icon-02.png" width="100%" />
                </td>
                <td class="px-md-3">
                  <h6>Descuentos en refacciones</h6>
                  <p class="text-muted">
                    Mantén en óptimas condiciones tu unidad de transporte al mejor precio con los descuentos exclusivos para integrantes de Traxporta.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img class="img-store w-100" src="@/assets/images/icon-03-1.png" width="100%" />
                </td>
                <td class="px-md-3">
                  <h6>Descuentos en seguros</h6>
                  <p class="text-muted">Respaldo ante eventualidades como accidentes o incidencias con descuentos especiales.</p>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <a :href=URL_REGISTER target="_blank" type="button" class="btn" style="background-color: #b8db15">Únete</a>
          </div>

        </div>
      </div>
    </div>

    <div class="container mt-5">
      <h2 class="text-center">Preguntas Frecuentes</h2>
    </div>

    <div class="container mt-5">
      <div class="row justify-content-md-center mb-5">
        <div class="col-md-10 offset-md-1">
          <div id="accordion">
            <div class="card">
              <div class="card-header collapsed" id="headingOne"  data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse">
                    ¿CÓMO PUEDO SABER SI LA EMPRESA QUE PIDE EL SERVICIO ES UNA EMPRESA LEGAL?
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingTwo"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿CÓMO PUEDO SABER LOS REQUERIMIENTOS ESPECÍFICOS DEL VIAJE?
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingThree"  data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿CÓMO PUEDO OBTENER LA DOCUMENTACIÓN, EN CASO DE NECESITARLA, PARA CUMPLIR CON UN SERVICIO?
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header collapsed" id="headingFour"  data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <h5 class="mb-0">
                  <button class="btn btn-link btn-collapse collapsed">
                    ¿QUIÉN ES EL QUE ME PAGARÁ Y CÓMO SE REALIZARÁ MI PAGO?
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import Blog from "../components/Blog"

export default {
  name: "Carrier",
  components: {
    Blog
  },
  data() {
    return {
      URL_REGISTER: process.env.VUE_APP_URL_REGISTER
    }
  }
}
</script>

<style scoped>
.page-body{
  height: auto;
  background-image: url('../assets/images/background-02.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.page-div{
  height: auto;
  background-image: url('../assets/images/truck-2.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.text-muted{
  color: #888888;
}

.bg-body{
  background: url('../assets/images/background-02.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (min-width: 768px) {
  .bg-body{
    height: 350px;
  }
  .body-loads{
    height: 450px;
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  .bg-body{
  }
  .body-loads{
    height: 300px;
  }
}
.bg-truck-1{
  background: url('../assets/images/truck-2.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 350px;
}

.card-header{
  cursor: pointer;
  background-color: #fff;
  color: black;
}

.card-header > h5 > button{
  color:rgb(184, 219, 21);
}

.collapsed > h5 > button{
  color:rgb(0, 0, 0);
}

.collapsed{
  cursor: pointer;
  background-color: #fff;
}

.btn-collapse{
  font-weight: 800;
  text-align: left;
}

.btn-link:hover {
  color:rgb(184, 219, 21);
  text-decoration: none;
}

.btn-link:focus{
  text-decoration: none;
}

.btn:focus{
  outline: none !important;
  box-shadow: none;
}
</style>