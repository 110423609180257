var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name !== 'home')?_c('div',{staticClass:"w-100 page-header",style:(_vm.HeaderStyle)},[_c('div',{staticClass:"container px-5"},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark px-0"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarToggler"}},[_c('ul',{staticClass:"navbar-nav mr-auto mt-2 mt-lg-0"},_vm._l((_vm.menu),function(item,index){return _c('li',{key:'menu-'+index,staticClass:"nav-item"},[_c('nav-link',{attrs:{"route":item.route,"title":item.title}})],1)}),0)])]),_vm._t("content")],2)]):_c('div',{staticClass:"w-100"},[_vm._m(2),_c('div',{staticClass:"container page-header-home px-5"},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark px-0"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarToggler"}},[_c('ul',{staticClass:"navbar-nav mr-auto mt-2 mt-lg-0"},_vm._l((_vm.menu),function(item,index){return _c('li',{key:'menu-'+index,staticClass:"nav-item"},[_c('nav-link',{attrs:{"route":item.route,"title":item.title}})],1)}),0)])]),_vm._m(5)]),_vm._t("content")],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarToggler","aria-controls":"navbarToggler","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"nav-logo",attrs:{"src":require("@/assets/images/logo_white.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"gradient"}),_c('div',{staticClass:"video-container"},[_c('video',{staticClass:"fillWidth visible-lg",attrs:{"autoplay":"","loop":""}},[_c('source',{attrs:{"src":require("@/assets/images/truck.mp4"),"type":"video/mp4; "}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarToggler","aria-controls":"navbarToggler","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"nav-logo",attrs:{"src":require("@/assets/images/logo_white.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abs-center mt-5"},[_c('h1',{staticClass:"text-center",staticStyle:{"color":"white"}},[_vm._v("LA NUEVA FORMA "),_c('br'),_vm._v(" DE MOVER AL MUNDO")])])
}]

export { render, staticRenderFns }